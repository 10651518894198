import { SupportedPaymentMethod } from './supported-payment-method';
import { MedicalScreening } from './medical-screening';
import { MapsInfoMessage } from './maps-info-message';
import { Awin } from './awin';
import { TheIdol } from './the-idol';
import { Dotdigital } from './dotdigital';
import { SchemeConfig } from './scheme-config';
import { Campaign } from './campaign';
import { Trustpilot } from './trustpilot';
import { ContentSnippet } from './content-snippet';

export class Agent {

  public agentId: number;

  public name: string;

  public url: string;

  public customerSiteUrl: string;

  public privacyNoticeUrl: string;

  public cookieDomain: string;

  public generalEmail: string;

  public enquiriesEmail: string;

  public requestPolicyRefundEmailTo: string;

  public requestPolicyDateChangeEmailTo: string;

  public brandColourPrimary: string;

  public brandColourSecondary: string;

  public brandColourTertiary: string;

  public googleTagManagerId: string;

  public underwriterId: number;

  public defaultCampaignId: number;

  public paymentAccountId: number;

  public supportedPaymentMethods: SupportedPaymentMethod[];

  public quoteExpiryLimitDays: number;

  public isCostOfHolidayRequired: boolean;

  public maxNumberOfTravellers: number;

  public inferFamilyGroup: boolean;

  public medicalScreening: MedicalScreening;

  public mapsInfoMessage: MapsInfoMessage;

  public awin: Awin;

  public theIdol: TheIdol;

  public dotdigital: Dotdigital;

  public schemeConfig: SchemeConfig;

  public campaigns: Campaign[];

  public trustpilot: Trustpilot;

  public contentSnippets: ContentSnippet[];

  public dateToday: string;

}