import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Agent } from '../models/agent';
import { ContentSnippet } from '../models/content-snippet';
import { SchemeGroup } from '../models/scheme-group';
import { Residency } from '../models/residency';
import { FamilyGroup } from '../models/family-group';
import { SchemeAdditional } from '../models/scheme-additional';

@Injectable({
  providedIn: 'root'
})

export class AppConfigService {

  private readonly _configDataFileUrl: string = './assets/data/app-config.json';

  public agentId: number;
  public defaultAppTitle: string;
  public quoteApiBaseUrl: string;
  public agent: Agent = new Agent();

  constructor(private _http: HttpClient) {

  }

  public load(): Promise<any> {

    const promise = this._http.get(this._configDataFileUrl)
      .toPromise()
      .then(data => {
        Object.assign(this, data);
        return data;
      })
      .then(async () => {
        const url = `${this.quoteApiBaseUrl}/clientconfig/getagent/${this.agentId}`;

        const data = await this._http.get(url)
          .toPromise();

        Object.assign(this.agent, data);
        return data;
      });

    return promise;

  }

  public getAgentContentSnippetById(id: string): ContentSnippet {

    let snippet: ContentSnippet = new ContentSnippet();

    if(this.agent.contentSnippets) {
      let foundSnippet: ContentSnippet;
      let searchStr: string = id;

      foundSnippet = this.agent.contentSnippets.find(({ id }) => id === searchStr);

      if(foundSnippet) return foundSnippet;
    }

    return snippet;
  }

  public getFooterContentSnippet(): ContentSnippet {

    const id = 'footer_copyTemplate';

    let snippet: ContentSnippet = this.getAgentContentSnippetById(id);

    if(snippet) {

      let dateToday: Date = new Date(this.agent.dateToday);

      // Replace the placeholder with year in template copy.
      snippet.value = snippet.value.replace('{0}', dateToday.getFullYear().toString());
    }

    return snippet;

  }

  public getResidencyById(id: number): Residency {

    const res = this.agent.schemeConfig.residencies.find(({ residencyId }) => residencyId === id);

    if(res === undefined) throw new Error(`No residency found with Id = ${id}.`);

    return res;
  }

  public getAgentFamilyGroupById(id: number): FamilyGroup {

    const fg = this.agent.schemeConfig.familyGroups.find(({ familyGroupId }) => familyGroupId === id);

    if(fg === undefined) throw new Error(`No family group found with Id = ${id}.`);

    return fg;
  }

  public getAgentSchemeGroupById(id: number): SchemeGroup {

    const sg = this.agent.schemeConfig.schemeGroups.find(({ schemeGroupId }) => schemeGroupId === id);

    if(sg === undefined) throw new Error(`No scheme group found with Id = ${id}.`);

    return sg;
  }

  public getAgentSchemeAdditionalById(id: number): SchemeAdditional {

    let sa = this.agent.schemeConfig.schemeAdditionals.find(({ schemeId }) => schemeId === id);

    // If the SchemeAdditional isn't found, we need to return an object with a
    // default state to avoid breaking when configuration may be missing.
    if(sa === undefined) {
      sa = { schemeId: id, defaqtoStarRating: 0, coronaCoverIncluded: false };
    }

    return sa;
  }

}
